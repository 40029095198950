import { I18n } from 'i18n-js'
import translations from './locales.json'

const i18n = new I18n(translations)
i18n.defaultLocale = 'en'
i18n.locale = document.documentElement.lang

export { i18n }
window.i18n = i18n
window.I18n = i18n
